import React from "react";
import { Button, Card, Nav, Section, Container } from "../components";
import ContactForm from "../components/ContactForm";
import { Link } from "react-router-dom";

const Home: React.FC = () => {
  return (
    <>
      <Nav />
      <Section
        style={{
          backgroundImage: `url("/images/hero.png")`,
        }}
        className="bg-center-right relative bg-right bg-no-repeat"
      >
        <Container className="relative z-20 grid grid-cols-1 gap-4">
          <div>
            <h1 className="mb-4 max-w-xl text-6xl font-light leading-none">
              Unleash Your Creativity: Experience the Impact of Exceptional
              Design and Development
            </h1>
            <p className="max-w-xl text-2xl font-light">
              Elevate Your Digital Presence: Crafting Seamless and Engaging User
              Experiences for Both Businesses and Individuals
            </p>
            <Button className="mt-10" href="#contact">
              Connect with Us
            </Button>
          </div>
        </Container>
      </Section>
      <Section
        id="about"
        className="bg-gradient-to-b from-slate-600 to-slate-500 text-center text-white"
      >
        <Container className="mx-auto max-w-xl">
          <h2 className="mb-4 text-4xl font-semibold">About Us</h2>
          <p>
            Established in 2005, our team of seasoned UX/UI designers and
            software developers have been empowering businesses and transforming
            digital experiences through innovative solutions and cutting-edge
            technology.
          </p>
        </Container>
      </Section>
      <Section id="services">
        <Container>
          <div className="mx-auto max-w-xl text-center">
            <h2 className="mb-4 text-4xl font-semibold">Our Services</h2>
            <p>
              From custom web and mobile application development to
              comprehensive UX/UI design, our team of experts provides tailored
              solutions that meet your unique business needs and drive
              exceptional user engagement.
            </p>
          </div>
          <div className="mt-8 grid grid-cols-1 gap-4 tablet:grid-cols-2 laptop:grid-cols-4">
            <Card>
              <h3 className="mb-1 text-lg font-semibold leading-6">
                Web Design & Development
              </h3>
              <p>
                From sleek corporate websites to creative portfolios, we craft
                responsive and visually appealing web solutions.
              </p>
            </Card>
            <Card>
              <h3 className="mb-1 text-lg font-semibold leading-6">
                Mobile App Design & Development
              </h3>
              <p>
                We create and build intuitive and engaging mobile app interfaces
                for both iOS and Android platforms.
              </p>
            </Card>
            <Card>
              <h3 className="mb-1 text-lg font-semibold leading-6">
                Brand Identity
              </h3>
              <p>
                Define your brand that stands out. We create cohesive and
                memorable branding that captures your vision and resonates with
                your audience, ensuring a lasting impression.
              </p>
            </Card>
            <Card>
              <h3 className="mb-1 text-lg font-semibold leading-6">
                Content writing
              </h3>
              <p>
                Boost your brand with compelling, SEO-optimized content that
                drives engagement and connects with your audience. From blogs to
                web copy, our writing elevates your online presence.
              </p>
            </Card>
          </div>
        </Container>
      </Section>
      <Section className="bg-slate-200">
        <Container className="text-center">
          <h2 className="mx-auto mb-4 max-w-xl text-4xl font-semibold">
            Empowering Businesses with Innovative Solutions
          </h2>
          <p className="mx-auto max-w-xl">
            Discover how our comprehensive UX/UI design and software development
            services can elevate your brand, streamline your business processes,
            and significantly enhance user satisfaction, ensuring a seamless and
            engaging experience for all your customers.
          </p>
        </Container>
      </Section>
      <Section className="bg-slate-800 text-white">
        <Container className="flex flex-col items-center gap-6 tablet:flex-row">
          <div className="flex-1 tablet:pl-0 tablet:pr-10">
            <h2 className="mb-4 text-4xl font-semibold">
              Unlock Your Digital Potential
            </h2>
            <p>
              Experience the difference with our comprehensive UX/UI design and
              software development services. From custom web and mobile
              applications to custom software development, we can help you
              unlock your digital needs.
            </p>
            <Button
              variant="outline"
              className="mt-10 text-white"
              href="#contact"
            >
              Schedule an appointment now
            </Button>
          </div>
          <div className="flex-1 tablet:px-0">
            <img src="/images/elements.png" alt="" />
          </div>
        </Container>
      </Section>
      <ContactForm></ContactForm>
      <footer className="bg-slate-200 text-center">
        <Container>
          <div className="mx-auto max-w-xl">
            <p>© 2024 Lab Radical, Inc. All rights reserved. </p>
            <p className="leading-2 mt-3 text-sm text-slate-500">
              This website is designed and developed by a UX/UI and software
              developer freelancer to showcase their expertise in crafting
              exceptional user experiences.
            </p>
            <p className="leading-2 mt-3 text-sm">
              <a
                href="mailto:info@labradical.com.au"
                className="text-slate-500 underline"
              >
                info@labradical.com.au
              </a>
            </p>
            <p className="leading-2 mt-3 text-sm">
              <Link to="colors-tints-shades" className="opacity-50">
                Tints and Shades Generator
              </Link>
            </p>
          </div>
        </Container>
      </footer>
    </>
  );
};

export default Home;
